<template>
	<b-container class="result">
		<b-tabs content-class="mt-4 px-3" justified>
			<b-tab v-if="sessionRaw.has_ended" title="헤드라인 기사 순위">
				<visualize-article-count :article-count="headlines_unique_count" :top-n="6" @show-article="showArticle" :styles="{ height: '400px', position: 'relative' }" />
				<b-row>
					<b-col class="chart-description">신문 편집 화면에서 헤드라인 기사로 가장 많이 선택된 기사 순위입니다. (전체 {{ headlines_unique_count.length }}종 중 상위 6종)</b-col>
				</b-row>
			</b-tab>
			<b-tab v-if="sessionRaw.has_ended" title="기사섹션별 결과">
				<visualize-category v-if="!is_remote" :articles="articles" :categories="categories" :styles="{ height: '400px', position: 'relative' }" :unique_count="submissions_category_unique_count" :unique_id="submissions_unique_id" />
				<visualize-category-m-e v-else :section-count="session.article_count_by_section_id" :styles="{ height: '400px', position: 'relative' }" />
				<b-row>
					<b-col class="chart-description">이번 수업에서는 "{{ submissions_category_unique_count_ordered[0].category }}" 분야 기사가 전체 {{ submissions.length }}건 중 총 {{ submissions_category_unique_count_ordered[0].count }}건으로 가장 많이 제출됐습니다.</b-col>
				</b-row>
			</b-tab>
			<b-tab v-if="sessionRaw.has_ended" title="전체기사 순위">
				<visualize-article-count :article-count="submissions_unique_count" :top-n="10" :styles="{ height: '540px', position: 'relative' }" @show-article="showArticle" />
				<b-row>
					<b-col class="chart-description">이번 수업을 통틀어 가장 많이 제출된 기사 순위입니다. (전체 {{ submissions_unique_count.length }}종 중 상위 10종)</b-col>
				</b-row>
			</b-tab>
			<b-tab title="기자별 신문 상태">
				<!--         <div v-if="!is_remote">
          <b-spinner v-if="loadingPDF" label="PDF 로딩 중..."></b-spinner>
          <b-list-group>
            <b-list-group-item v-for="pdf in pdfList" v-bind:key="pdfList.indexOf(pdf)" :href="pdf.pdf_url"> {{ decodeURI(pdf.name) }}</b-list-group-item>
          </b-list-group>
        </div> -->
				<!-- <div v-else> -->
				<player-list-table v-if="session.players.length" :session="session" :is-remote="is_remote" @print-start="$emit('print-start')" @print-stop="$emit('print-stop')" />
				<div v-else>
					<span class="text-secondary">학생들이 프레스코드를 이용해 게임에 접속하면 이곳에 표시됩니다.</span>
				</div>
				<b-button class="mt-3 orange" @click="$emit('refresh')" :disabled="refreshing">
					<span v-if="!refreshing">목록 새로고침</span>
					<b-spinner v-else small class="mb-1 mx-4" />
				</b-button>
				<!-- </div> -->
			</b-tab>
		</b-tabs>
		<!-- 
    <div class="mobile">
      <p class="header">
        수업 결과
      </p>
      <b-row>
        <b-col class="header"> 헤드라인 인기 순위 </b-col>
      </b-row>
      <b-row>
        <b-col class="chart-description">신문 편집 화면에서 헤드라인 기사로 가장 많이 선택된 기사 순위입니다. (헤드라인 선택 여부 무관, 전체 {{ headlines_unique_id.length }}종 중 상위 6종)</b-col>
      </b-row>
      <b-row>
        <b-col class="chart">
        <VisualizeArticleCount
          :aspect-ratio="2"
          :article-count="submissions_unique_count"
          :unique-id="submissions_unique_id"
          :top-n="10"
        />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="header"> 기사섹션별 결과 </b-col>
      </b-row>
      <b-row>
        <b-col class="chart-description">이번 수업에서는 "{{ submissions_category_unique_count_ordered[0].category }}" 분야 기사가 전체 {{ submissions.length }}건 중 총 {{ submissions_category_unique_count_ordered[0].count }}건으로 가장 많이 제출됐습니다.</b-col>
      </b-row>
      <b-row>
        <b-col class="chart">
          <VisualizeSubmissionCategory
            :articles="articles"
            :categories="categories"
            :aspectRatio="1.2"
            :unique_count="submissions_category_unique_count"
            :unique_id="submissions_unique_id" />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="header"> 전체기사 순위 </b-col>
      </b-row>
      <b-row>
        <b-col class="chart-description">이번 수업을 통틀어 가장 많이 제출된 기사 순위입니다. (헤드라인 선택 여부 무관, 전체 {{ submissions_unique_id.length }}종 중 상위 10종)</b-col>
      </b-row>
      <b-row>
        <b-col class="chart">
          <VisualizeArticleCount
            :aspect-ratio="2"
            :article-count="submissions_unique_count"
            :unique-id="submissions_unique_id"
            :top-n="10"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="header"> 기자별 신문 링크 </b-col>
      </b-row>
      <b-row align-h="center">
        <b-spinner v-if="loadingPDF" label="PDF 로딩 중..."></b-spinner>
        <b-list-group>
          <b-list-group-item v-for="pdf in pdfs" v-bind:key="pdfs.indexOf(pdf)" :href="pdf.pdf_url"> {{ decodeURI(pdf.name) }}</b-list-group-item>
        </b-list-group>
      </b-row>
    </div> -->
		<article-modal :article-id="articleId" />
	</b-container>
</template>

<script>
// TODO 신문 제출한 기록 없을 때 에러 대응
// TODO: Tab 옮겨다닐 때 화면 맨 위로 스크롤되는 동작 방지
/* eslint-disable */

import VisualizeArticleCount from './VisualizeArticleCount';
import VisualizeCategory from './VisualizeSubmissionCategory';
import VisualizeCategoryME from './VisualizeSubmissionCategoryMatchingEngine';
import PlayerListTable from '@/components/SessionResultPlayerListTable';
import ArticleModal from '@/components/ArticleModal';

const articles = require('../assets/articles.json');
const categories = {
	1: '정치',
	2: '경제/외교',
	3: '사회',
	4: '문화예술',
	5: '스포츠',
	6: '사람들',
};

export default {
	components: {
		VisualizeArticleCount,
		VisualizeCategory,
		VisualizeCategoryME,
		PlayerListTable,
		ArticleModal,
	},
	data() {
		return {
			articles,
			categories,
			articleId: 0,
			// showArticleModal: false
		};
	},
	props: ['sessionRaw', 'pdfs', 'loadingPDF', 'refreshing'],
	computed: {
		is_remote() {
			return this.sessionRaw.is_remote;
		},
		session() {
			const session = this.sessionRaw;
			if (this.is_remote) {
				return session.remote_session;
			} else {
				return session;
			}
		},
		players() {
			return this.session.players;
		},
		pdfList() {
			if (this.is_remote) {
				return [];
			}
			return JSON.parse(this.session.pdf_urls).sort((a, b) => a.name.localeCompare(b.name));
		},
		players_pdf() {
			if (this.is_remote) {
				return [];
			}
			const playersArray = this.players.filter((player) => player.pdf_log.length > 0).map((player) => player.pdf_log.split('&'));
			const playersObject = playersArray.map((player) => {
				let playerObject = {
					head: [],
					main: [],
					others: [],
					all: [],
				};
				player.forEach((param) => {
					let [k, v] = param.split('=');
					if (k === 'head' || k === 'main' || k === 'others') {
						const articleArray = v.split('.');
						playerObject[k].push({
							article: parseInt(articleArray[0]),
							image: parseInt(articleArray[1]),
							tag: parseInt(articleArray[2]),
						});
						playerObject.all.push({
							article: parseInt(articleArray[0]),
							image: parseInt(articleArray[1]),
							tag: parseInt(articleArray[2]),
						});
					}
				});
				return playerObject;
			});
			return playersObject;
		},

		// 신규방식 (pdf 로그 사용)
		headlines_id() {
			return this.players_pdf.map((player) => player.head[0].article);
		},
		headlines() {
			const articles = this.headlines_id.map((id) => this.articles.find((article) => article.id === id));
			// console.log(`${articles.length} headlines among ${this.players_pdf.length} players`)
			return articles;
		},
		headlines_unique_count() {
			if (this.is_remote) {
				return this.session.headline_article_count;
			} else {
				const unique_id = [...new Set(this.headlines.map((a) => a.id))];
				let counts = unique_id.map(
					(id) =>
						new Object({
							id: id,
							count: this.headlines.filter((article) => article.id === id).length,
							category: this.categories[this.headlines.find((article) => article.id === id).category],
							title_ko: this.headlines.find((article) => article.id === id).title_ko,
							keyword_ko: this.headlines.find((article) => article.id === id).keyword_ko,
						})
				);
				// count 값 기준으로 내림정렬
				counts.sort((a, b) => b.count - a.count);
				// console.log(counts)
				return counts;
			}
		},
		// headlines_unique_id () {
		//   // 기사 식별자 unique array (count 값 기준으로 내림정렬됨)
		//   return this.headlines_unique_count.map(c => c.id)
		// },
		submissions() {
			let submission_id = [];
			this.players_pdf.forEach((player) => player.all.forEach((a) => submission_id.push(a.article)));
			const submissions = submission_id.map((id) => this.articles.find((article) => article.id === id));
			// console.log(`${submissions.length} total articles among ${this.players_pdf.length} players`)
			return submissions;
		},
		submissions_unique_count() {
			if (this.is_remote) {
				return this.session.article_count;
			} else {
				const unique_id = [...new Set(this.submissions.map((a) => a.id))];
				let counts = unique_id.map(
					(id) =>
						new Object({
							id: id,
							count: this.submissions.filter((article) => article.id === id).length,
							category: this.categories[this.submissions.find((article) => article.id === id).category],
							title_ko: this.submissions.find((article) => article.id === id).title_ko,
							keyword_ko: this.submissions.find((article) => article.id === id).keyword_ko,
						})
				);
				// count 값 기준으로 내림정렬
				counts.sort((a, b) => b.count - a.count);
				return counts;
			}
		},
		submissions_unique_id() {
			// 기사 식별자 unique array (count 값 기준으로 내림정렬됨)
			return this.submissions_unique_count.map((c) => c.id);
		},
		submissions_category_unique_count() {
			const unique_category = [...new Set(this.submissions.map((a) => a.category))];
			const counts = Object.keys(this.categories).map(
				(category) =>
					new Object({
						category: this.categories[category],
						count: this.submissions.filter((article) => article.category === parseInt(category)).length,
					})
			);
			return counts;
		},
		submissions_category_unique_count_ordered() {
			let orderedCounts = [...this.submissions_category_unique_count];
			// count 값 기준으로 내림정렬
			orderedCounts.sort((a, b) => b.count - a.count);
			return orderedCounts;
		},
	},
	mounted() {
		// console.log(this.pdfList)
		// console.log(this.players)

		console.log(this.sessionRaw);
		console.log(this.pdfs);
		console.log(this.loadingPDF);
		console.log(this.refreshing);
	},
	methods: {
		showArticle(articleId) {
			console.log('show');
			this.articleId = articleId;
			this.$bvModal.show('article-modal');
			// this.showArticleModal = true
		},
		hideArticle() {
			console.log('hide');
			// this.showArticleModal = false
		},
	},
};
</script>

<style scoped>
/*.tabs >>> .nav, */
/*.tabs >>> .nav-tabs, */
.tabs >>> .nav-item,
.tabs >>> .nav-link,
.tabs >>> .nav-link.active,
.tabs >>> .nav-link:focus,
.tabs >>> .nav-link:hover {
	border: none;
}
.tabs >>> .nav-link {
	color: #111111;
	word-break: keep-all;
}
.tabs >>> .nav-link.active {
	color: #45a673;
	font-weight: bold;
	border-bottom: 2px solid #45a673;
}

.chart-description {
	font-size: 0.8em;
	color: gray;
	padding: 0;
}

.container .header.col {
	border-bottom: none;
}

@media (max-width: 576px) {
	.row {
		margin: 0;
	}
	.col {
		padding-left: 10px;
		padding-right: 10px;
	}
}
</style>
