<script>
/* eslint-disable */
import { Radar } from 'vue-chartjs'

// import 'chartjs-plugin-colorschemes'
import { BlueLight7 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau'
const colorScheme = Array.from(BlueLight7)
colorScheme.reverse()

// 차트 위에 텍스트 레이블 입력할 수 있게 해주는 플러그인
// import ChartDataLabels from 'chartjs-plugin-datalabels'
// Chart.plugins.unregister(ChartDataLabels)

export default {
  extends: Radar,
  props: ['sectionCount', 'aspectRatio'],
  mounted () {
    const chartData = {
      labels: this.sectionCount.map(s => s.title),
      titles: this.sectionCount.map(s => null),
      datasets: [{
        label: null,
        data: this.sectionCount.map(s => s.count),
        fill: false,
        // backgroundColor: Chart['colorschemes'].tableau.OrangeLight7.reverse(),
        backgroundColor: colorScheme,
        borderWidth: 2,
        borderColor: 'darkgray'
      }]
    }
    const options = {
      // aspectRatio: this.aspectRatio,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scale: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 6,
        },
        pointLabels: {
          fontSize: 14
        }
      },
      events: [],
      animation: {
        duration: 500,
        easing: 'easeOutCubic'
      },
      plugins: {
        datalabels: {
          formatter: (value, context) => context.chart.data.titles[context.dataIndex],
          align: 'end',
          anchor: 'start',
          clamp: true,
          color: 'black',
          font: {
            size: 16
            // style: 'bold'
          }
        }
      }
    }
    // this.addPlugin({ChartDataLabels})
    this.renderChart(chartData, options)
  }
}
</script>

<style scoped>
</style>
