<template>
	<!-- TODO 각 컴포넌트 및 서브컴포넌트 안 중앙정렬 점검 -->
	<div class="view" v-if="loaded" :class="{ printing: printing }">
		<!-- <app-alert-modal v-if="!$store.getters.appPopupAcknowledged()&&!session.has_started&&!session.has_ended" /> -->

		<div class="main">
			<b-row>
				<b-col class="view-title mb-3">
					{{ viewTitle }}
					<span v-if="session.has_started && !session.has_ended">
						<b-badge variant="success">진행중</b-badge>
						<span title="수업 정보 새로고침" :class="['icon', 'icon-refresh', { refreshing: refreshing }]" @click="refreshSession" />
					</span>
				</b-col>
			</b-row>
			<b-row align-h="center">
				<b-col class="view-description mb-3">
					{{ viewDescription }}
				</b-col>
			</b-row>
			<!-- v-bind (:): 변수 바인딩. 현재 컴포넌트의 변수를 하위 컴포넌트 속성으로 보내줍니다 -->
			<!-- v-on (@): 하위 컴포넌트 이벤트 리스너 -->
			<!-- v-if: 컴포넌트 보여주기 조건 -->
			<b-row cols="1" cols-lg="3" align-h="center">
				<b-col>
					<session-info :session="session" />
				</b-col>
				<b-col v-if="!session.has_ended">
					<session-code :session="session" @start="startSession" @end="endSession" />
				</b-col>
				<b-col v-if="session.has_started && !session.is_remote && false">
					<!-- TODO DELETE -->
					<SessionPrint @end="endSession" @print="printSession" :session="session" />
				</b-col>
			</b-row>
			<b-row v-if="session.has_started && !session.has_ended" align-h="center">
				<b-col>
					<b-button variant="outline-dark" class="mb-4" @click="forceShowSessionInvite = !forceShowSessionInvite">
						초대 링크
						<span v-if="!forceShowSessionInvite">다시 보기</span>
						<span v-else>감추기</span>
					</b-button>
				</b-col>
			</b-row>
			<b-row v-if="showSessionInvite" cols="1">
				<b-col>
					<session-invite :session="session" />
				</b-col>
			</b-row>
			<b-row v-if="session.has_started" cols="1">
				<b-col>
					<SessionResultYeoju v-if="session.class_type.includes('영릉 시간탐험대')" :session-raw="session" :loadingPDF="loadingPDF" :refreshing="refreshing" @refresh="refreshSession" />
					<session-result v-else :session-raw="session" :loadingPDF="loadingPDF" :refreshing="refreshing" @print-start="localPrintStart" @print-stop="localPrintStop" @refresh="refreshSession" />
				</b-col>
			</b-row>
			<b-row align-h="center">
				<b-button :pressed="false" href="#" v-on:click="cancelSessionConfirm">수업 삭제하기</b-button>
			</b-row>
			<feedback-icon />
		</div>
	</div>
</template>

<script>
import SessionResult from '@/components/SessionResult';
import SessionResultYeoju from '@/components/SessionResultYeoju';

import SessionInfo from '@/components/SessionInfo';
import SessionCode from '@/components/SessionCode';
import SessionInvite from '@/components/SessionInvite';
import SessionPrint from '@/components/SessionPrint';
import FeedbackIcon from '@/components/FeedbackIcon';
// import AppAlertModal from '@/components/AppAlertModal'

export default {
	name: 'Session',
	metaInfo: {
		// 페이지 제목 설정
		title: '수업 상세',
		titleTemplate: '%s | NOLGONG SCHOOL',
		meta: [
			// SEO 설정
			{
				name: 'description',
				content:
					'학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소> | ‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'description',
			},
			{ name: 'author', content: '(주)놀공' },
			{ name: 'subject', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
		],
	},
	props: ['session_id'],
	data() {
		return {
			// 교사 이메일 주소
			email: '',
			loaded: false,
			// 세션 데이터
			session: null,
			loadingPDF: false,
			printing: false,
			refreshing: false,
			forceShowSessionInvite: false,
		};
	},
	components: {
		SessionInfo,
		SessionCode,
		SessionInvite,
		SessionPrint,
		SessionResult,
		SessionResultYeoju,
		FeedbackIcon, // AppAlertModal
	},
	computed: {
		// 세션 상태에 따른 제목 변경을 위한 속성
		viewTitle() {
			if (this.session.has_ended) {
				return '수업 결과';
			}
			return '수업 진행';
		},
		viewDescription() {
			if (this.session.has_ended) {
				return '완료된 수업입니다.';
			} else if (this.session.has_started) {
				if (this.session.is_active) {
					if (this.session.class_type.includes('영릉 시간탐험대')) {
						return '진행 중인 수업입니다.\n"게임 종료"를 누르면 프레스코드가 비활성화되고 수업 결과를 확인할 수 있습니다.';
					} else {
						return '진행 중인 수업입니다.\n"게임 종료"를 누르면 프레스코드가 비활성화되고 수업 결과를 확인할 수 있습니다.\n학생들이 앱에서 "기사 송고하기"를 누르도록 안내해주세요!';
					}
				} else {
					return '프레스코드가 만료되었습니다.\n"게임 종료"를 눌러 수업 결과를 확인하세요.';
				}
			} else {
				return '아직 진행되지 않은 수업입니다.\n수업 당일에 "수업 시작" 버튼을 누르면 프레스코드가 활성화됩니다';
			}
		},
		showSessionInvite() {
			if (this.forceShowSessionInvite) {
				return true;
			}
			return this.session.is_remote && !this.session.has_started;
		},
	},
	methods: {
		// 유저 정보 조회
		getUser() {
			this.$store
				.dispatch('GET_USER')
				.then((res) => {
					this.email = this.$store.state.email;
					return res;
				})
				.catch((e) => e);
		},
		// 세션 조회
		getSession() {
			this.$store.dispatch('GET_SESSION', { session_id: this.session_id }).then((response) => {
				// 요청이 정상적으로 처리
				if (this.$store.state.serverStatus === 200) {
					// 응답으로 받아온 세션 데이터를 컴포넌트 데이터로 복사한다
					// https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
					this.session = response.data;
					this.loaded = true;
					// console.log(response.data)
				}
				// 요청 실패
				if (this.$store.state.serverStatus === 400) {
					console.log('error');
				}
				// 존재하지 않거나 액세스 권한 없는 세션 id로 요청 실패
				if (this.$store.state.serverStatus === 404) {
					this.$router.push('/home');
					// window.location.reload()
					// alert('이런 식으로 들어오시면 안 됩니다.')
				}
				// 토큰 기간 만료로 요청 실패
				if (this.$store.state.serverStatus === 401) {
					this.$router.push('/login');
					// window.location.reload()
					alert('다시 로그인하십시오.');
				}
			});
		},

		refreshSession() {
			// 아이콘을 회전시키고 세션 정보를 다시 불러온다
			this.refreshing = true;
			setTimeout(() => {
				this.refreshing = false;
			}, 1000);
			this.getSession();
		},

		startSession() {
			this.$store.dispatch('START_SESSION', { session_id: this.session_id }).then((response) => {
				this.session.has_started = response.data.has_started;
				this.session.press_code = response.data.press_code;
			});
		},

		cancelSessionConfirm() {
			if (window.confirm('등록된 수업을 삭제하시겠습니까?')) {
				this.cancelSession();
			}
		},

		cancelSession() {
			this.$store.dispatch('CANCEL_SESSION', { session_id: this.session_id }).then((response) => {
				if (response.status < 300 && response.status >= 200) {
					this.$router.push('/home');
				} else {
					alert('서버와 통신 중 오류가 발생했습니다. 정상적으로 취소되었는지 수업 목록에서 확인해주세요.');
					console.log('에러가 있었습니다...');
				}
			});
		},

		endSession() {
			this.$store.dispatch('END_SESSION', { session_id: this.session_id }).then((response) => {
				if (this.$store.state.serverStatus === 200) {
					this.session.has_ended = response.data.has_ended;
					if (!this.session.is_remote) {
						this.printSession();
					}
				} else {
					// TODO: 오류시 UI에 메시지 표시

					console.log('에러가 있었습니다...');
				}
			});
		},

		printSession() {
			// DEPRECATED?
			this.$store
				.dispatch('PRINT_SESSION', { session_id: this.session_id })
				.then((response) => {
					return this.$store.dispatch('UPDATE_SESSION_PDF', {
						session_id: this.session_id,
						pdfs: JSON.stringify(response.data.pdfs),
					});
				})
				.then((response) => {
					if (response.status === 200) {
						// do something to show user that request was successful
						// alert('학생들이 작성한 신문 PDF파일이 선생님의 이메일로 전송되었습니다. 아래에서도 PDF목록을 확인하실 수 있습니다.')
						// alert('아래에서 학생들이 작성한 신문 PDF목록을 확인하실 수 있습니다.')
						// this.resultTabIndex = 3
						this.loadingPDF = true;
						setTimeout(() => {
							this.loadingPDF = false;
						}, 3000);
						setTimeout(this.getSession, 3000);
					} else {
						// TODO: 오류시 UI에 메시지 표시
						console.log('에러가 있었습니다...');
					}
				});
		},

		localPrintStart() {
			this.printing = true;
		},
		localPrintStop() {
			this.printing = false;
		},
	},

	mounted() {
		this.getUser();
		this.getSession();
	},
};
</script>

<style scoped>
/* 프레스 코드 폰트 지정 */
@import url('https://fonts.googleapis.com/css2?family=Allerta+Stencil&display=swap');

/*.container >>> .press-code, .container >>> .student-count {
  font-family: 'Allerta Stencil', sans-serif;
  font-size: 3em;
  text-align: center;
  letter-spacing: 0.1em;
}*/

.view-description {
	max-width: 700px;
	white-space: pre;
}

.view.printing {
	cursor: wait;
}

.refreshing {
	transition: 1s;
	transform: rotate(360deg);
}
@media (min-width: 992px) {
	.container {
		max-width: 588px;
		min-height: 15rem;
	}
	.container.result {
		max-width: 720px;
	}
	.main {
		max-width: 960px;
		margin: auto;
	}
}
/*
@media (min-width: 1200px) {
  .container {
    max-width: 588px;
  }
}
*/
</style>
