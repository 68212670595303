<template>
  <b-modal
    v-show="article"
    id="article-modal"
    :title="loading ? '' : article.title" 
    header-class="text-center"
    centered hide-footer>
    <b-row v-if="loading" align-v="center">
      <b-col class="text-center">
        <b-spinner v-if="loading" />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <b-row>
          <b-col class="text-left">&ndash; {{ article.title_sub }} </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <img class="w-100" :src="article.image_url">
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center font-italic">
            #{{ article.section_title }} #{{ article.word }}
            <!-- {{ article.region }} -->
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-justify">
            <br />
            {{ article.completed_article }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  props: ['articleId'],
  data () {
    return {
      article: null,
      loading: true
    }
  },
  watch: {
    articleId () {
      this.getArticle()
    }
  },
  methods: {
    getArticle () {
      if (this.articleId > 0 && this.articleId <= 54) {
        this.loading = true
        this.$Axios
          .get(`https://matching-engine.nolple.com/wallpeckers/articles?articles=[${this.articleId}]&lang=ko`)
          .then(response => response.data)
          .then(data => {
            this.article = data[this.articleId]
            this.loading = false
          })
      } else {
        this.article = null
      }
    },
  },
  mounted () {
    this.getArticle()
  },
}
</script>

<style scoped>
</style>