<template>
	<b-container class="session-code">
		<p class="header">
			PRESS CODE
		</p>
		<b-row>
			<b-col :class="['press-code', 'text-center', { 'mt-2': session.is_active || (session.has_started && !session.has_ended), 'mt-4': !session.is_active && !session.has_started, 'text-muted': !session.has_started }]">
				{{ session.press_code }}
			</b-col>
		</b-row>
		<b-row v-if="session.is_active || session.has_started" align-h="center">
			<b-col class="p-0 text-center">
				<b-button v-if="!session.has_started" @click="startClass" class="px-5 orange"> 게임 시작 </b-button>
				<b-button v-else @click="showEndClassModalOne" class="px-5" variant="danger"> 게임 종료 </b-button>
			</b-col>
		</b-row>
		<b-modal id="start-class-modal" title="게임이 시작되었습니다." ok-only ok-title="확인" ok-variant="success" centered body-class="h5" header-class="text-center" content-class="text-center" footer-class="justify-content-center">
			프레스코드({{ session.press_code }})를 학생들에게 알려주세요. <br />

			<!--
      {{ session.class_type.includes('앳홈') ? '월페커즈앳홈 모바일/PC웹사이트' : '월페커즈인스쿨 모바일웹사이트' }}
      <a v-if="!session.class_type.includes('앳홈')" href="https://wallpeckers.miraeclass.com" target="_blank">
      (wallpeckers.miraeclass.com)</a>로 접속 가능한 게임입니다.
      -->
		</b-modal>
		<b-modal id="end-class-modal-1" size="lg" ok-title="네" ok-variant="danger" cancel-title="취소" centered hide-header body-class="h5" content-class="text-center" footer-class="justify-content-center" @ok="showEndClassModalTwo">
			<p v-if="session.class_type.includes('영릉 시간탐험대')">
				중요! <br />
				게임을 종료하기 전, 학생들이 퇴청하기를 눌렀는지 <br />확인해 주세요.<br />
			</p>
			<p v-else>
				중요! <br />
				게임을 종료하기 전, 학생들이 신문편집과 송고하기를 끝냈는지 확인해 주세요.<br />
				(* 게임상의 ‘결과 출력소’에서 신문 편집하기 후 저장하고 종료하기)<br />
				<br />
				[주의] ‘신문 저장하고 종료하기’ 전에 게임을 종료하면 신문이 발행되지 않습니다.<br />
				<br />
				신문 송고하기까지 완료되었나요?
			</p>
		</b-modal>
		<b-modal id="end-class-modal-2" hide-header ok-title="네" ok-variant="danger" cancel-title="취소" centered body-class="h5" content-class="text-center" footer-class="justify-content-center" @ok="endClass">
			게임을 종료하고 결과를 확인하시겠습니까?<br />
			이번에 사용한 프레스코드는 더 사용할 수 없게 됩니다.
		</b-modal>
	</b-container>
</template>

<script>
export default {
	name: 'SessionCode',
	props: ['session'],
	methods: {
		startClass() {
			this.$emit('start');
			this.showStartClassModal();
		},
		endClass() {
			this.$emit('end');
		},
		showStartClassModal() {
			this.$bvModal.show('start-class-modal');
		},
		showEndClassModalOne() {
			this.$bvModal.show('end-class-modal-1');
		},
		showEndClassModalTwo() {
			this.$bvModal.show('end-class-modal-2');
		},
	},
	mounted() {
		// this.showEndClassModalOne()
	},
};
</script>

<style scoped>
.container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
.press-code {
	font-family: 'Allerta Stencil', sans-serif;
	font-size: 3em;
	letter-spacing: 0.1em;
}
.text-muted {
	opacity: 0.5;
}
@media (min-width: 992px) {
	.session-code {
		max-height: 20vh;
	}
}
</style>
