<script>
/* eslint-disable */
import { Radar } from 'vue-chartjs'

// import 'chartjs-plugin-colorschemes'
import { BlueLight7 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau'
const colorScheme = Array.from(BlueLight7)
colorScheme.reverse()

// 차트 위에 텍스트 레이블 입력할 수 있게 해주는 플러그인
// import ChartDataLabels from 'chartjs-plugin-datalabels'
// Chart.plugins.unregister(ChartDataLabels)

export default {
  extends: Radar,
  name: 'VisualizeSubmissionCategory',
  props: ['articles', 'categories', 'unique_id', 'unique_count'],
  computed: {
    // 기존 방식 (플레이 로그 조회)
    // submissions () {
    //   const players_with_submission = this.players.filter(player => player.articles.length > 0)
    //   let submission_id = []
    //   players_with_submission.forEach(player => player.articles.forEach(a => submission_id.push(a.article)))
    //   const submissions = submission_id.map(id => this.articles.find(article => article.id === id))
    //   console.log(submissions)
    //   return submissions
    // },

    // 신규방식 (pdf 로그 사용)
    // submissions () {
    //   let articles_id = []
    //   this.players_pdf.forEach(player => player.all.forEach(a => articles_id.push(a.article)))
    //   const submissions = articles_id.map(id => this.articles.find(article => article.id === id))
    //   return submissions
    // },
    // unique_count () {
    //   const unique_category = [...new Set(this.submissions.map(a => a.category))]
    //   // console.log(unique_category)
    //   // console.log(Object.keys(this.categories))
    //   // let counts = unique_category.map(category => new Object({
    //   //   category: this.categories[category],
    //   //   count: this.submissions.filter(article => article.category === category).length
    //   // }))
    //   const counts = Object.keys(this.categories).map(category => new Object({
    //     category: this.categories[category],
    //     count: this.submissions.filter(article => article.category === parseInt(category)).length
    //   }))
    //   let orderedCounts = [...counts] 
    //   // count 값 기준으로 내림정렬
    //   orderedCounts.sort((a, b) => b.count - a.count)
    //   // console.log(counts)
    //   // console.log(orderedCounts)

    //   return counts
    // },
    // unique_id () {
    //   // 기사 식별자 unique array
    //   return this.unique_count.map(c => c.id)
    // },
    unique_title () {
      // 기사 제목 unique array
      return this.unique_id.map(id => this.articles.find(article => article.id === id).title_ko)
    },
    ranking_labels () {
      // unique한 식별자 개수가 n일 때, 1~n까지의 레이블을 담은 배열. 순위 표시용.
      return [...Array(this.unique_id.length).keys()].map(i => i + 1)
    }
  },
  mounted () {
    const topN = 10
    const labels = this.ranking_labels.slice(0, topN)
    const values = this.unique_count.slice(0, topN)

    const chartData = {
      labels: Object.values(this.categories),
      titles: values.map(c => null),
      datasets: [{
        label: null,
        data: values.map(c => c.count),
        fill: false,
        // backgroundColor: Chart['colorschemes'].tableau.OrangeLight7.reverse(),
        backgroundColor: colorScheme,
        borderWidth: 2,
        borderColor: 'darkgray'
      }]
    }
    const options = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scale: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 6,
        },
        pointLabels: {
          fontSize: 14
        }
      },
      events: [],
      animation: {
        duration: 500,
        easing: 'easeOutCubic'
      },
      plugins: {
        datalabels: {
          formatter: (value, context) => context.chart.data.titles[context.dataIndex],
          align: 'end',
          anchor: 'start',
          clamp: true,
          color: 'black',
          font: {
            size: 16
            // style: 'bold'
          }
        }
      }
    }
    // this.addPlugin({ChartDataLabels})
    this.renderChart(chartData, options)
  }
}
</script>

<style scoped>
</style>
