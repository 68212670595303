<template>
	<b-container class="result">
		<player-list-table v-if="session.players.length" :session="sessionRaw.remote_session" :session_id="sessionRaw.id" :is-remote="sessionRaw.is_remote" @print-start="$emit('print-start')" @print-stop="$emit('print-stop')" />
		<div v-else>
			<span class="text-secondary">학생들이 프레스코드를 이용해 게임에 접속하면 이곳에 표시됩니다.</span>
		</div>

		<b-button class="mt-3 orange" @click="$emit('refresh')" :disabled="refreshing">
			<span v-if="!refreshing">목록 새로고침</span>
			<b-spinner v-else small class="mb-1 mx-4" />
		</b-button>
	</b-container>
</template>

<script>
import PlayerListTable from '@/components/SessionResultPlayerListTableYeoju';

export default {
	name: 'SessionResultYeoju',

	components: {
		PlayerListTable,
	},

	props: ['sessionRaw', 'pdfs', 'loadingPDF', 'refreshing'],
	// props: {
	//     sessionRaw: {
	//         type: Object,
	//     },
	//     pdfs: Object, ???
	//     loadingPDF: Boolean,
	//     refreshing: Boolean
	// },

	// data() {
	//     return {
	//         session: null,
	//     }
	// },

	computed: {
		session: function() {
			return this.sessionRaw.remote_session;
		},
	},

	mounted() {
		console.log(this.session);
		console.log(this.sessionRaw);
		console.log(this.pdfs);
		console.log(this.loadingPDF);
		console.log(this.refreshing);

		// this.session = this.sessionRaw.remote_session
	},
};
</script>

<style></style>
