const worker = new Worker('./pdfWorker.js', { type: 'module', name: 'pdf-worker' });
const multiWorker = new Worker('./multiplePdfWorker.js', { type: 'module', name: 'multiple-pdf-worker' });

const send = message => {
  if (message.requestType === 'single') {
    worker.postMessage({ message })
  } else if (message.requestType === 'multiple') {
    multiWorker.postMessage({ message })
  }
}

export default {
  worker,
  multiWorker,
  send
}