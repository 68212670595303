<script>
import { HorizontalBar } from 'vue-chartjs'

// import 'chartjs-plugin-colorschemes'
// https://nagix.github.io/chartjs-plugin-colorschemes/
import { BlueLight7 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau'
const colorScheme = Array.from(BlueLight7)
colorScheme.reverse()

// 차트 위에 텍스트 레이블 입력할 수 있게 해주는 플러그인
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
  extends: HorizontalBar,
  plugins: { ChartDataLabels },
  props: ['articleCount', 'topN'],
  computed: {
    articles () {
      return [...this.articleCount].sort((a, b) => b.count - a.count).slice(0, this.topN)
    },
    titles () {
      return this.articles.map(article => {
        // 매칭엔진에서는 category, keyword, title
        // 기존 프레스룸 서버에서는 section, keyword_ko, title_ko
        const section = article.category ? article.category : article.section
        const keyword = article.keyword ? article.keyword : article.keyword_ko
        const title = article.title ? article.title : article.title_ko
        return `[${section}:${keyword}] ${title}`
      })
    },
    rankingLabels () {
      // unique한 식별자 개수가 n일 때, 1~n까지의 레이블을 담은 배열. 순위 표시용.
      return [...Array(this.articles.length).keys()].map(i => i+1)
    }
  },
  methods: {
  },
  mounted () {
    const chartData = {
      labels: this.rankingLabels,
      titles: this.titles,
      articleIdArray: this.articles.map(article => article.id),
      datasets: [{
        label: null,
        data: this.articles.map(article => article.count),
        fill: false,
        // backgroundColor: Chart['colorschemes'].tableau.OrangeLight7.reverse(),
        backgroundColor: colorScheme,
        borderWidth: 1,
        borderColor: 'rgba(0,0,0,0.1)'
      }]
    }
    const options = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            beginAtZero: true,
            display: false,
          },
        }],
        yAxes: [{
          gridLines: {
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            fontSize: 16,
            padding: 10,
          }
        }]
      },
      onClick: (event, activeElements) => {
        if (activeElements.length === 0) {
          console.log("Chart is clickable but you must click a data point to drill-down")
        } else {
          const chart = activeElements[0]._chart
          const activePoints = chart.getElementsAtEventForMode(event, 'point', chart.options)
          const firstPoint = activePoints[0]
          const articleId = chart.data.articleIdArray[firstPoint._index]
          // this.getArticleData(articleId)
          this.$emit('show-article', articleId)
          // const label = chart.data.labels[firstPoint._index]
          // const value = chart.data.datasets[firstPoint._datasetIndex].data[firstPoint._index]
          // console.log(label + ": " + value)
        }
      },
      events: ['click', 'touch'], 
      tooltips: {
        displayColors: false,
        callbacks: {
          title: () => null,
          label: tooltipItem => `${tooltipItem.value}건`,
        } 
      },
      animation: {
        duration: 500,
        easing: 'easeOutCubic',
      },
      plugins: {
        datalabels: {
          formatter: (value, context) => context.chart.data.titles[context.dataIndex],
          align: 'end',
          anchor: 'start',
          clamp: true,
          color: 'black',
          font: {
            size: 16,
            // style: 'bold',
          }
        }
      }  
    }
    this.renderChart(chartData, options)
  }
}
</script>
