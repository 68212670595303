<template>
	<b-container class="session-print">
		<p class="header">
			신문 편집 완료한 학생 수
		</p>
		<b-row>
			<b-col class="student-count text-center mt-2">
				{{ player_submit_count }}
			</b-col>
		</b-row>
		<b-row v-if="session.is_remote" align-h="center">
			<b-button block v-if="!session.has_ended" href="#" @click="$emit('end')" class="orange"> 기사 마감하고 결과 보기 </b-button>
		</b-row>
		<b-row v-else align-h="center">
			<b-button block v-if="!session.has_ended" href="#" @click="$emit('end')" class="orange"> 기사 마감하고 결과 보기 </b-button>
			<b-button block v-else-if="button_pressed" disabled> <b-spinner small/></b-button>
			<b-button block v-else href="#" @click="printNews" class="orange"> 이메일로 신문 다시 받기 </b-button>
		</b-row>
	</b-container>
</template>

<script>
export default {
	name: 'SessionPrint',
	props: ['pdfList', 'session'],
	data() {
		return {
			button_pressed: false,
		};
	},
	computed: {
		remoteSession() {
			if (this.session.is_remote) {
				return this.session.remote_session;
			} else {
				return {};
			}
		},
		player_submit_count() {
			if (this.session.is_remote) {
				return this.remoteSession.player_count;
			}
			const result = this.session.players.filter((player) => Object.prototype.hasOwnProperty.call(player, 'pdf_log') && player.pdf_log !== '');
			return result.length;
		},
	},
	methods: {
		printNews() {
			// TODO remote session일 경우 다르게 처리
			this.$emit('print');

			// 인쇄 버튼을 연속으로 누르지 못하게 5초 쿨타임을 줍니다
			this.button_pressed = true;
			setTimeout(() => {
				this.button_pressed = false;
			}, 5000);
		},
	},
};
</script>

<style scoped>
.student-count {
	font-family: 'Allerta Stencil', sans-serif;
	font-size: 3em;
	letter-spacing: 0.1em;
}
@media (min-width: 992px) {
	.session-print {
		max-height: 20vh;
	}
}
</style>
