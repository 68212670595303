<template>
	<b-container class="session-info">
		<p class="header">수업 과목: {{ session.class_type }}</p>
		<b-row cols="3" cols-sm="4" align-h="center" class="mobile">
			<b-col
				><p class="cell text-center">{{ session.title ? session.title : '놀공스쿨 수업' }}</p></b-col
			>
			<b-col
				><p class="cell text-center">{{ session.start_date.substring(5, 10).replace('-', '/') }} {{ session.start_date.substring(11, 16) }}</p></b-col
			>
			<b-col
				><p class="cell text-center">{{ session.play_time }}분</p></b-col
			>
		</b-row>
		<b-row align-h="around" class="desktop">
			<b-col class="col-5"><p class="cell text-center">제목/메모</p></b-col>
			<b-col
				><p class="cell text-center">{{ session.title ? session.title : '놀공스쿨 수업' }}</p></b-col
			>
		</b-row>
		<b-row align-h="around" class="desktop">
			<b-col class="col-5"><p class="cell text-center">수업 일시</p></b-col>
			<b-col
				><p class="cell text-center">{{ session.start_date.substring(5, 10).replace('-', '/') }} {{ session.start_date.substring(11, 16) }}</p></b-col
			>
		</b-row>
		<b-row align-h="around" class="desktop">
			<b-col class="col-5"><p class="cell text-center">게임 길이</p></b-col>
			<b-col
				><p class="cell text-center">{{ session.play_time }}분</p></b-col
			>
		</b-row>
	</b-container>
</template>

<script>
export default {
	name: 'SessionInfo',
	props: ['session'],
	data() {
		return {};
	},
};
</script>

<style scoped>
.container .row {
	margin: 0;
}
@media (min-width: 992px) {
	.session-info {
		max-height: 20vh;
	}
	.row {
		padding-top: 1.5rem;
	}
	.mobile {
		display: none;
	}
}
@media (max-width: 576px) {
	.row {
		margin: 0;
	}
	.col {
		padding-left: 10px;
		padding-right: 10px;
	}
}
@media (max-width: 991px) {
	.desktop {
		display: none;
	}
}
</style>
