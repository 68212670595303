<template>
	<b-row class="m-0 p-0">
		<b-col class="p-0">
			<b-row class="m-0 p-0">
				<b-col class="text-right mx-0 mb-2">
					<form>
						<input name="pdf-filter" v-model="onlyShowPlayersWithPdf" type="checkbox" />
						<label for="pdf-filter" class="ml-1" @click="onlyShowPlayersWithPdf = !onlyShowPlayersWithPdf"> 신문 제출 완료한 플레이어만 보기 </label>
					</form>
				</b-col>
			</b-row>
			<b-row class="m-0 px-0 pt-0 pb-3 font-weight-bold">
				<b-col cols="1" class="p-0 text-center desktop-only">#</b-col>
				<b-col cols="5" sm="5" class="p-0 text-center">이름</b-col>
				<b-col cols="2" class="p-0 text-center word-break-keep-all">기자 레벨</b-col>
				<b-col cols="2" class="p-0 text-center">포인트</b-col>
				<!-- <b-col cols="2" class="p-0 text-center">취재기사 수</b-col> -->
				<b-col cols="2" class="p-0 text-center word-break-keep-all">신문 보기</b-col>
			</b-row>
			<b-row v-for="(p, i) in playersSorted" :key="p.id" class="mx-0 px-0 py-3 border-top">
				<b-col cols="1" class="p-0 text-center desktop-only">{{ i + 1 }}</b-col>
				<b-col cols="5" sm="5" class="p-0 text-center">{{ p.name }}</b-col>
				<b-col cols="2" class="p-0 text-center word-break-keep-all">{{ p.level ? p.level : '-' }}</b-col>
				<b-col cols="2" class="p-0 text-center">{{ p.score }}</b-col>
				<!-- <b-col cols="2" class="p-0 text-center">{{ p.article_count }}</b-col> -->
				<b-col cols="2" class="p-0 text-center">
					<b-button v-if="p.print_info" pill :disabled="showAlert" class="orange py-0 text-nowrap" @click="printPDF(p.print_info)">PDF</b-button>
				</b-col>
			</b-row>
			<b-row v-if="pdfList.length" class="mx-0">
				<b-col cols="10" class="p-0" />
				<b-col cols="2" class="p-0 text-center">
					<b-button class="orange word-break-keep-all px-0" :disabled="showAlert" @click="printMultiplePDF(pdfList)">모든 PDF 다운로드</b-button>
				</b-col>
			</b-row>
			<b-toast v-model="showAlert" title="PDF 생성 중..." toaster="b-toaster-bottom-center" no-auto-hide no-close-button>
				<b-row class="m-0 p-0">
					<b-col class="p-0 text-center">
						<b-progress :value="progress.value" :max="progress.max" variant="success" animated class="mb-2" />
						<b-spinner small label="PDF 생성 중..." v-if="printing" class="m-1" />
						<span>{{ printMessage }}</span>
					</b-col>
				</b-row>
			</b-toast>
		</b-col>
	</b-row>
</template>

<script>
// TODO 플레이어 게임 상태 (시작, 취재중, 완료 등) 추가 검토
// 플레이어별 신문을 인쇄하는 기능은 worker가 처리한다.
import pdfWorker from '@/workers';
// import { saveAs } from 'file-saver'

export default {
	data() {
		return {
			progress: {
				value: 0,
				max: 0,
			},
			printing: false,
			showAlert: false,
			printMessage: 'PDF 파일을 생성하고 있습니다. (웹브라우저를 닫으면 파일 생성이 취소됩니다!)',
			workerResponse: {},
			onlyShowPlayersWithPdf: false,
		};
	},
	props: ['session', 'isRemote'],
	mounted() {
		// this.$nextTick(() => {
		//   pdfWorker.worker.onmessage = this.handleWorkerMessage
		// })
		// console.log(this.playersSorted)
	},
	computed: {
		pdfList() {
			const players = this.playersSorted.filter((p) => p.print_info);
			const pdfList = players.map((p) => p.print_info);
			return pdfList;
		},
		playersParsed() {
			// 매칭엔진과 프레스룸 데이터 포맷이 다르기 때문에 통일해준다.
			// player.keys: name, score, level, article_count, print_info
			const players = this.session.players;
			if (this.isRemote) {
				return players.map((p) => {
					return {
						name: p.profile.info.wallpeckers ? p.profile.info.wallpeckers.name : p.profile.name,
						score: p.status.score,
						level: ['', '인턴 기자', '신입 기자', '편집 기자', '편집장', '주필'][p.status.level],
						article_count: Object.keys(p.status.complete_articles).length,
						print_info: p.print_log_info,
					};
				});
			} else {
				return players.map((p) => {
					const pdfLog = new URLSearchParams(p.pdf_log);
					return {
						name: p.name,
						score: p.score,
						level: p.level,
						article_count: p.article_count,
						print_info: p.pdf_log.length ? 	
							{
								name: p.name,
								head: pdfLog.getAll('head'),
								main: pdfLog.getAll('main'),
								others: pdfLog.getAll('others'),
								lang: 'ko',
								created_time: p.pdf_logged_at.replace('T', ' '),
							}
							: null,
					};
				});
			}
		},
		playersSorted() {
			const players = [...this.playersParsed];
			players.sort((a, b) => {
				return a.name.localeCompare(b.name);
			});
			if (this.onlyShowPlayersWithPdf) {
				return players.filter((p) => p.print_info);
			} else {
				return players;
			}
		},
	},
	methods: {
		printPDF(data) {
			// this.$bvToast.show('pdf-progress')
			this.showAlert = true;
			this.startWorker(data, 'single');
		},
		printMultiplePDF(pdfList) {
			this.showAlert = true;
			this.startWorker(pdfList, 'multiple');
		},
		startWorker(data, requestType) {
			pdfWorker.worker.onmessage = this.handleWorkerMessage;
			pdfWorker.multiWorker.onmessage = this.handleWorkerMessage;
			if (this.printing) {
				return false;
			} else {
				this.progress.value = 0;
				this.printing = true;
				this.$emit('print-start');
				pdfWorker.send({
					info: data,
					requestType,
				});
			}
		},
		saveBlob() {
			const a = document.createElement('a');
			a.href = this.workerResponse.url;
			a.download = this.workerResponse.filename;
			a.click();
		},
		handleWorkerMessage(event) {
			if (event.data.type === 'progress-value') {
				this.progress.value = event.data.message;
			} else if (event.data.type === 'progress-max') {
				this.progress.max = event.data.message;
			} else if (event.data.type === 'pdf' || event.data.type === 'zip') {
				// 완성된 pdf 또는 zip을 worker로부터 반환받았을 경우
				this.workerResponse = event.data;
				this.printMessage = 'PDF 생성이 완료되었습니다.';
				this.printing = false;
				this.$emit('print-stop');
				window.setTimeout(() => {
					// 1초 후 알림 메시지를 감춘다
					this.showAlert = false;
				}, 1000);
				window.setTimeout(() => {
					// 1.5초 후 알림 문구를 초기화한다
					this.printMessage = 'PDF 파일을 생성하고 있습니다. (웹브라우저를 닫으면 파일 생성이 취소됩니다!)';
				}, 1500);
				this.saveBlob();
			}
		},
	},
};
</script>

<style>
.word-break-keep-all {
	word-break: keep-all;
}
.desktop-only {
	display: none;
}
@media (min-width: 576px) {
	.desktop-only {
		display: block;
	}
}
</style>
